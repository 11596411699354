import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { axiosClient, createFile, getProduct, postEvent, SendMailToClient, SendMailToGest, submitTags } from '~/api';
import axios, { all } from 'axios';
import _ from 'lodash';

import ChatBot from './ChatBot';
import MatterportViewer from './MatterportViewer';
import { UploadFile } from '../blocs/UploadFile';

import './DeclareEvent.scss';

const DeclareEvent = () => {
  const { idProduct } = useParams();

  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.up('xs'));
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesXl = useMediaQuery(theme.breakpoints.up('xl'));

  const [allDatas, setAllDatas] = useState(null);
  const [pannelWidth, setPannelWidth] = useState(0);
  const [matterportIframeWidth, setMatterportIframeWidth] = useState(0);
  const [hasMatterportScan, setHasMatterportScan] = useState(null);

  const [matterportFrameVisible, setMatterportFrameVisible] = useState(false);

  const [chatTree, setChatTree] = useState(null);
  const [backUpChatTree, setBackUpChatTree] = useState(null);

  const [answerTree, setAnswerTree] = useState(null);

  const [currentIdQuestion, setCurrentIdQuestion] = useState(0);

  const [openModalUploadFile, setOpenModalUploadFile] = useState(false);

  const [openModalTutorial, setOpenModalTutorial] = useState(false);

  const [isPortraitMode, setIsPortraitMode] = useState(false);
  const [isLandscapeMode, setIsLandscapeMode] = useState(false);

  const [blockAllAnswers, setBlockAllAnswers] = useState(false);

  const [files, setFiles] = useState([]);
  const [tags, setTags] = useState([]);

  const hasMatterportScanRef = useRef(null);

  const finalAnswerTree = useRef([]);

  const validateTags = () => {
    console.log('validateTags', tags);
    setMatterportFrameVisible(false);
  };

  const validateFiles = () => {
    console.log('validateFiles', files);
  };

  const validateAnswer = (idAnswer, idQuestion, inputAnswers = null) => {
    console.log(answerTree);
    console.log('validateAnswer', idAnswer, idQuestion, inputAnswers);
    setCurrentIdQuestion(_.find(chatTree, { id: idQuestion }).responses[idAnswer].nextQuestion);

    const dumpAnswerTree = JSON.parse(JSON.stringify(answerTree));

    Object.entries(dumpAnswerTree).forEach((value) => {
      if (value[1] && value[1]['id'] > idQuestion) {
        delete dumpAnswerTree[value[0]];
      }
    });

    console.log('item to update', dumpAnswerTree[_.findIndex(dumpAnswerTree, { id: idQuestion })]);

    dumpAnswerTree[_.findIndex(dumpAnswerTree, { id: idQuestion })].answers = inputAnswers;

    const nextQuestion = _.find(chatTree, {
      id: _.find(chatTree, { id: idQuestion }).responses[idAnswer].nextQuestion
    });

    console.log('nextQuestion', nextQuestion);

    dumpAnswerTree[nextQuestion.id] = JSON.parse(JSON.stringify(nextQuestion));

    console.log('dumpAnswerTree', dumpAnswerTree);

    setAnswerTree(Object.values(dumpAnswerTree));
    finalAnswerTree.current = Object.values(dumpAnswerTree);
  };

  const onResizeHandler = () => {
    let pannelWidthVar = 0;
    if (window.innerWidth < 600) {
      pannelWidthVar = window.innerWidth;
    } else if (window.innerWidth < 900) {
      pannelWidthVar = 300;
    } else if (window.innerWidth < 1200) {
      pannelWidthVar = 400;
    } else if (window.innerWidth < 1536) {
      pannelWidthVar = 500;
    } else if (window.innerWidth >= 1536) {
      pannelWidthVar = 600;
    }

    setPannelWidth(pannelWidthVar);

    setMatterportIframeWidth(window.innerWidth - pannelWidthVar);

    if (window.innerWidth < window.innerHeight) {
      setIsPortraitMode(true);
      setIsLandscapeMode(false);
    } else {
      setIsPortraitMode(false);
      setIsLandscapeMode(true);
    }

    // console.log('hasMatterportScan', hasMatterportScan);

    if (hasMatterportScanRef.current === false) {
      setPannelWidth(window.innerWidth);
    }
  };

  const sendDatas = () => {
    console.log('sendDatas');

    console.log(finalAnswerTree.current);

    let description = '';

    finalAnswerTree.current.forEach((answer, index) => {
      if (index > 0 && answer.id !== 101 && answer.id !== 80 && answer.id !== 81) {
        // on zappe le premier qui sont les infos de contact
        console.log(answer);

        if (answer.responses[0].type === 'input') {
          if (answer.answers[0].value.length > 1) {
            description += answer.recapText + ' : ' + answer.answers[0].value + '\n';
          }
        } else {
          description += answer.recapText + ' : ' + answer.responses[answer.answers.index].responseText + '\n';
        }
      }
    });

    console.log(description);

    const sendEmails = (eventData, hasTags) => {
      const emailList = [];

      allDatas.contact.forEach((contact) => {
        emailList.push(contact.email);
      });

      console.log(emailList);

      SendMailToClient(eventData).then(() => {
        SendMailToGest(eventData, emailList, hasTags).then(() => {});
      });
    };

    let kindEvent = 1;

    if (finalAnswerTree.current[1].answers.index === 0) {
      kindEvent = finalAnswerTree.current[2].responses[finalAnswerTree.current[2].answers.index].kindEvent;
    } else {
      kindEvent = finalAnswerTree.current[1].responses[finalAnswerTree.current[1].answers.index].kindEvent;
    }

    console.log('kindEvent', kindEvent);

    const payload = {
      address: {
        street: allDatas.property.address.street,
        city: allDatas.property.address.city,
        zip_code: allDatas.property.address.zip_code
      },
      uuid_owner: idProduct,
      uuid_organisation: allDatas.organization.uuid,
      title: finalAnswerTree.current[1].responses[finalAnswerTree.current[1].answers.index].responseText,
      desc: description,
      kind: kindEvent,
      data: {
        contact_requestor: {
          name: finalAnswerTree.current[0].answers[0].value,
          phone: finalAnswerTree.current[0].answers[1].value,
          email: finalAnswerTree.current[0].answers[2].value
        }
      }
    };

    console.log(payload);

    postEvent(payload).then((eventData) => {
      console.log(eventData);
      if (tags.length > 0) {
        const tagsToSend = [];

        tags.forEach((tag) => {
          tagsToSend.push({
            uuid_organization: allDatas.organization.uuid,
            uuid_owner: eventData.uuid,
            owner_kind: 'event',
            position: {
              x: tag.position.x,
              y: tag.position.y,
              z: tag.position.z,
              steam_x: tag.normal.x,
              steam_y: tag.normal.y,
              steam_z: tag.normal.z
            },
            sid: tag.id
          });
          submitTags(tagsToSend);
        });
      }

      if (files.length > 0) {
        const filePromises = [];
        files.forEach((file) => {
          console.log(file);
          const formData = new FormData();
          formData.append('file', file);
          formData.append('uuid_organization', allDatas.organization.uuid);
          formData.append('uuid_owner', eventData.uuid);
          formData.append('owner_kind', 'event');

          createFile(formData)
            .then((response) => {
              console.log(response);
            })
            .catch((error) => {
              console.log(error);
            });
        });

        Promise.all(filePromises)
          .then((values) => {
            console.log(values);
          })
          .catch((error) => {
            console.log(error);
          });
      }

      eventData.address = {
        street: allDatas.property.address.street,
        city: allDatas.property.address.city,
        zip: allDatas.property.address.zip_code
      };
      sendEmails(eventData, tags.length > 0);
    });
  };

  useEffect(() => {
    if (chatTree) {
      setAnswerTree([chatTree[0]]);
    }
  }, [chatTree]);

  const concatFiles = (files) => {
    setFiles((prev) => {
      return [...prev, ...files];
    });
  };

  const suppressFile = (index) => {
    setFiles((prev) => {
      return prev.filter((file, i) => i !== index);
    });
  };

  useEffect(() => {}, [answerTree]);

  useEffect(() => {
    const productType = idProduct.split('-')[0] === 'pro' ? 'product' : 'building';
    axios.get(window.location.origin + '/chatTree.json').then((response) => {
      setChatTree(response.data.questions);
      setBackUpChatTree(response.data.questions);
    });

    getProduct(idProduct, productType).then((productData) => {
      axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + productData.jwt;
      setAllDatas(productData);
    });

    onResizeHandler();
    window.addEventListener('resize', onResizeHandler);
    return () => {
      window.removeEventListener('resize', onResizeHandler);
    };
  }, []);

  useEffect(() => {
    if (allDatas) {
      if (allDatas.property?.matterport_scan?.model_id) {
        setHasMatterportScan(true);
        hasMatterportScanRef.current = true;
      } else {
        setHasMatterportScan(false);
        hasMatterportScanRef.current = false;
        setPannelWidth(window.innerWidth);
      }
    }
  }, [allDatas]);

  useEffect(() => {
    if (allDatas && backUpChatTree) {
      console.log('allDatas', allDatas);

      const newChatTree = JSON.parse(JSON.stringify(backUpChatTree));
      if (allDatas.property?.matterport_scan?.model_id) {
      } else {
        newChatTree.forEach((question) => {
          console.log(question);
          question.responses.forEach((response) => {
            if (response.nextQuestion === 80) {
              response.nextQuestion = 100;
            }
          });
        });

        console.log(newChatTree);
        setChatTree(newChatTree);
      }
    }
  }, [allDatas, backUpChatTree]);

  useEffect(() => {
    console.log('tags', tags);
  }, [tags]);

  return (
    <>
      <Grid container id="declareEventMainContainer" style={{ position: 'fixed', width: '100vw', height: '100dvh' }}>
        {hasMatterportScan && (
          <MatterportViewer
            pannelWidth={pannelWidth}
            matterportFrameVisible={matterportFrameVisible}
            allDatas={allDatas}
            tags={tags}
            setTags={setTags}
            matterportIframeWidth={matterportIframeWidth}
            validateTags={validateTags}
          ></MatterportViewer>
        )}
        <Grid
          item
          id="chatBotContainer"
          style={{
            overflow: 'hidden',
            // backgroundColor: 'magenta',
            height: 'calc(100dvh)',
            width: hasMatterportScan ? pannelWidth + 'px' : '100%'
          }}
        >
          {allDatas && chatTree && (
            <ChatBot
              allDatas={allDatas}
              width={pannelWidth}
              chatTree={chatTree}
              answerTree={answerTree}
              validateAnswer={validateAnswer}
              setMatterportFrameVisible={setMatterportFrameVisible}
              setOpenModalUploadFile={setOpenModalUploadFile}
              setOpenModalTutorial={setOpenModalTutorial}
              tags={tags}
              setTags={setTags}
              hasMatterportScan={hasMatterportScan}
              blockAllAnswers={blockAllAnswers}
              setBlockAllAnswers={setBlockAllAnswers}
              files={files}
              sendDatas={sendDatas}
              // suppressFile={suppressFile}
            ></ChatBot>
          )}
        </Grid>
      </Grid>
      {/* {openModalUploadFile && ( */}
      <Dialog
        onClose={() => {
          setOpenModalUploadFile(false);
        }}
        open={openModalUploadFile}
        sx={{ width: '100vw' }}
      >
        <DialogContent
          sx={{
            maxWidth: '50vw',
            minWidth: '300px',
            padding: 0
          }}
        >
          <UploadFile setFile={setFiles} files={files} />
          <Button
            onClick={() => {
              setOpenModalUploadFile(false);
            }}
          >
            Ne pas associer de fichiers
          </Button>
          {files.length > 0 && (
            <Button
              onClick={() => {
                setOpenModalUploadFile(false);
                validateFiles();
              }}
            >
              Valider les fichiers associés
            </Button>
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={() => {
          setOpenModalTutorial(false);
        }}
        open={openModalTutorial}
      >
        {/* <UploadFile setFile={setFiles} /> */}
        <DialogTitle>{'Mode ajout de tag'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Pour ajouter un tag déplacez vous à l'endroit ou vous souhaitez, puis cliquez et maintenez votre clic
            jusqu'à l'ajout.
          </DialogContentText>
          <div style={{ textAlign: 'center', paddingTop: '10px' }}>
            <img
              style={{ maxWidth: isLandscapeMode ? '17vw' : '100vw', maxHeight: isPortraitMode ? '17vh' : '100vh' }}
              src={window.location.origin + '/tutorial-tag.gif'}
            ></img>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenModalTutorial(false);
            }}
          >
            J'ai compris
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeclareEvent;
